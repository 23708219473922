import React from 'react';
import './App.css';
import useUserAgent from './hooks/use-user-agent';
import ReactJson from 'react-json-view';
import { Switch, Route, BrowserRouter, useParams } from 'react-router-dom';
import useIPad from './hooks/use-ipad';

const FALLBACK_URL = 'https://electrifly.ru';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=ru.electrifly.facade';
const APP_STORE_URL = 'https://apps.apple.com/app/electrifly-charging-station/id1530713764';

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/cp/:identity" component={RedirectToNativeApp} />
                <Route
                    path="*"
                    component={() => {
                        window.location.href = FALLBACK_URL;
                        return null;
                    }}
                />
            </Switch>
        </BrowserRouter>
    );
}

function RedirectToNativeApp() {
    // useEffect(() => {
    //   window.location.replace("electrifly://megapp");
    // }, []);
    const ua = useUserAgent();
    const isIpad = useIPad();

    let { identity } = useParams<{ identity: string }>();

    if (ua.os?.name === 'iOS' || isIpad) {
        window.location.replace(`electrifly://facade/station/${identity}`);
        setTimeout(() => (window.location.href = APP_STORE_URL), 1000);
    } else if (ua.os?.name === 'Android') {
        window.location.replace(`electrifly://facade/station/${identity}`);
        setTimeout(() => (window.location.href = GOOGLE_PLAY_URL), 1000);
    } else {
        // setTimeout(() => {
        window.location.href = FALLBACK_URL;
        // }, 1000);
    }

    return (
        <div className="App">
            <a href="https://electrifly.ru">Перейти на главную</a>
        </div>
    );
}
