import React from 'react';

const useIPad = (): boolean => {
    const [navigator] = React.useState(window.navigator);
    const [document] = React.useState(window.document);

    const value = React.useMemo(() => {
        return (
            ['iPad Simulator', 'iPad'].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }, [navigator, document]);

    return value;
};

export default useIPad;
