import * as React from 'react';

import { UAParser } from 'ua-parser-js';

export interface UserAgentContext {
    os?: UAParser.IOS;
    device?: UAParser.IDevice;
    engine?: UAParser.IEngine;
    browser?: UAParser.IBrowser;
    cpu?: UAParser.ICPU;
}

const useUserAgent = (): UserAgentContext => {
    const [userAgent] = React.useState(window.navigator.userAgent);

    const value = React.useMemo(() => {
        const uaParser = new UAParser();
        uaParser.setUA(userAgent);
        return {
            os: uaParser.getOS(),
            browser: uaParser.getBrowser(),
            cpu: uaParser.getCPU(),
            device: uaParser.getDevice(),
            engine: uaParser.getEngine(),
        };
    }, [userAgent]);

    return value;
};

export default useUserAgent;
